/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Drawer } from 'antd';

import withGetService from '../../hoc';
import SelectLanguage from '../../language';
import { compose } from '../../../utils';

import Img from '../../small-components/img';
import logo from '../../assets/images/logo.svg';
import style from './header.module.scss';
import './header.scss';
import { contactPath } from '../../../constants';

export class HeaderSite extends Component {
    mounted = true;

    state = {
        mobileMenu: false,
    };

    componentDidMount() {
        setTimeout(() => {
            document.body.classList.add('domLoaded');
        }, 100);
    }

    // show mobile menu
    showDrawer = () => {
        if (this.mounted) {
            const { mobileMenu } = this.state;
            this.setState({
                mobileMenu: !mobileMenu,
            });
        }

        const button = document.getElementById('humburgerMenu');
        button.classList.toggle('active');
    };

    render() {
        const { mobileMenu } = this.state;
        const { t } = this.props;

        let drawerWidth;
        let drawerPaddingTop;
        if (window.innerWidth < 500) {
            drawerWidth = '100vw';
            drawerPaddingTop = '35vw';
        } else if (window.innerWidth > 499 && window.innerWidth < 1050) {
            drawerWidth = '50vw';
            drawerPaddingTop = '10vw';
        }

        const mobileMenuBody = {
            backgroundColor: '#FAFBFF',
            padding: `${drawerPaddingTop} 0 0 0`,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: 'inherit',
        };

        const activeStyle = {
            color: '#B2947B',
            fontWeight: '700',
            borderBottom: '0.14vw solid #B2947B',
        };

        return (
            <Fragment>
                <div className="filter">
                    <header className={style.header}>
                        <div className={style.header__leftSide}>
                            <div className="animationLogo">
                                <span
                                    
                                    // href="https://crocobit.net/"
                                    // target="_blank"
                                    // rel="noopener noreferrer"
                                    className={style.header__logo}
                                >
                                    <Img src={logo} alt="logo" />
                                </span>
                            </div>

                            <nav className={style.header__nav}>
                                <div className="animationNav">
                                    <ul className={style.header__nav_list}>
                                        <li>
                                            <NavLink
                                                to="/"
                                                activeStyle={activeStyle}
                                                exact
                                            >
                                                {t('extension.extension')}
                                            </NavLink>
                                        </li>

                                        <li>
                                            <a
                                                href="https://p2p.crocobit.net/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                P2P
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a
                                                href="https://crocobit.net/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t('extension.exchange')}
                                            </a>
                                        </li> */}
                                        {/* <li>
                                            <a
                                                href="https://cards.crocobit.net/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t('extension.cards')}
                                            </a>
                                        </li> */}
                                        {/* <li>
                                            <a
                                                href="https://ecrosystem.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                            Ecro system
                                            </a>
                                        </li> */}
                                        <li>
                                            <NavLink
                                                to={contactPath}
                                                activeStyle={activeStyle}
                                            >
                                                {t('footer.contacts')}
                                            </NavLink>
                                        </li>

                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div className={style.header__rightSide}>
                            <div className="animationSelectLang">
                                <SelectLanguage />
                                <div className={style.header__mobileMenu}>
                                    <svg
                                        className="ham hamRotate ham8"
                                        id="humburgerMenu"
                                        viewBox="0 0 100 100"
                                        onClick={this.showDrawer}
                                    >
                                        <path
                                            className="line top"
                                            d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
                                        />
                                        <path className="line middle" d="m 30,50 h 40" />
                                        <path
                                            className="line bottom"
                                            d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
                                        />
                                    </svg>
                                    <Drawer
                                        placement="right"
                                        closable={false}
                                        onClose={this.showDrawer}
                                        visible={mobileMenu}
                                        bodyStyle={mobileMenuBody}
                                        height="100vh"
                                        width={drawerWidth}
                                    >
                                        <ul
                                            className={style.header__mobileMenu_links}
                                            onClick={this.showDrawer}
                                        >
                                            <li>
                                                <NavLink
                                                    to="/"
                                                    activeStyle={activeStyle}
                                                    exact
                                                >
                                                    {t('extension.extension')}
                                                </NavLink>
                                            </li>

                                            <li>
                                                <a
                                                    href="https://p2p.crocobit.net/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    activeStyle={activeStyle}
                                                >
                                            P2P
                                                </a>
                                            </li>
                                            {/* <li>
                                                <a
                                                    href="https://crocobit.net/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    activeStyle={activeStyle}
                                                >
                                                    {t('extension.exchange')}
                                                </a>
                                            </li> */}
                                            {/* <li>
                                                <a
                                                    href="https://cards.crocobit.net/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t('extension.cards')}
                                                </a>
                                            </li> */}
                                            {/* <li>
                                                <a
                                                    href="https://ecrosystem.com/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                            Ecro system
                                                </a>
                                            </li> */}
                                            <li>
                                                <NavLink
                                                    to={contactPath}
                                                    activeStyle={activeStyle}
                                                >
                                                    {t('footer.contacts')}
                                                </NavLink>
                                            </li>
                                        </ul>
                                        <div className={style.header__mobileMenu_footer}>
                                            <p
                                                className={
                                                    style.header__mobileMenu_copyright
                                                }
                                            >
                                                Copyright
                                                <span
                                                    className={
                                                        style.header__mobileMenu_symbol
                                                    }
                                                >
                                                    &#169;
                                                </span>
                                                <span
                                                    className={
                                                        style.header__mobileMenu_p2p
                                                    }
                                                >
                                                    CROCOBIT
                                                </span>
                                            </p>
                                            <div className="mobileMenu__selectLanguage">
                                                <SelectLanguage />
                                            </div>
                                        </div>
                                    </Drawer>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </Fragment>
        );
    }
}

HeaderSite.defaultProps = {
    t: () => {},
};

HeaderSite.propTypes = {
    t: PropTypes.func,
};

export default compose(withTranslation(), withGetService(), withRouter)(HeaderSite);
