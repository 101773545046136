import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import style from './footer.module.scss';

const FooterPersonalArea = () => {
    const { t } = useTranslation();

    return (
        <footer className={style.footerPersonalArea}>
            <Link to="privacyPolicy" className={style.footer__links}>
                {t('footer.privacyPolicy')}
            </Link>
        </footer>
    );
};

export default FooterPersonalArea;
