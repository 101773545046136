import { message } from 'antd';

import i18n from '../i18n';
import store, { authHeader } from '../helpers';
import {
    ORDER_MAX_AMOUNT_ERROR,
    UNDEFINED_USER_WITH_USERNAME,
    CHAT_MESSAGE_MAX_ERROR,
    CHAT_MESSAGE_MIN_ERROR,
} from '../constants';
import { userActions } from '../actions';

export default class PostService {
    getResource = async (url, data) => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(data),
        };

        const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, requestOptions);

        if (res.status === 401) {
            this.removeUserFromStorage();
        }

        if (res.status !== 200 && res.status !== 401) {
            const errorMessage = await res.json();

            if (errorMessage.error === ORDER_MAX_AMOUNT_ERROR) {
                const {
                    parameters: { min, max },
                } = errorMessage;
                message.error(i18n.t('error.orderMaxAmount', { min, max }), 3);
            }

            if (
                errorMessage.error === CHAT_MESSAGE_MAX_ERROR
                || errorMessage.error === CHAT_MESSAGE_MIN_ERROR
            ) {
                const {
                    parameters: { min, max },
                } = errorMessage;
                message.error(i18n.t('error.messageMaxAmount', { min, max }), 3);
            }

            if (errorMessage.error === UNDEFINED_USER_WITH_USERNAME) {
                const { username } = errorMessage;
                message.error(
                    i18n.t('error.undefinedUserWithEmail', { mail: username }),
                    3,
                );
            }

            if (!res.ok) {
                return Promise.reject(errorMessage.error);
            }
        }

        if (!res.ok) {
            console.error(`Could not fetch ${url}, received ${res.status}`);
        }

        const result = await res.json();
        return result;
    };

    logout = async () => {
        const res = await this.getResource('/api/user/logout');
        return res;
    };

    removeUserFromStorage = () => {
        store.dispatch(userActions.logout());
    };

    coinCreate = async data => {
        const res = await this.getResource('/api/user/coin/create', data);
        return res;
    };

    chatCreate = async data => {
        const res = await this.getResource('/api/chat/create', data);
        return res;
    };

    declinePrice = async data => {
        const res = await this.getResource('/api/chat/offer/decline', data);
        return res;
    };

    notReceivedMoney = async data => {
        const res = await this.getResource('/api/chat/payment/decline', data);
        return res;
    };

    acceptPrice = async data => {
        const res = await this.getResource('/api/chat/offer/accept', data);
        return res;
    };

    iHavePaid = async data => {
        const res = await this.getResource('/api/chat/payment/send', data);
        return res;
    };

    ReceivedMoney = async data => {
        const res = await this.getResource('/api/chat/payment/accept', data);
        return res;
    };

    sendChatMessages = async data => {
        const res = await this.getResource('/api/chat/message/send', data);
        return res;
    };

    cancelTrade = async data => {
        const res = await this.getResource('/api/chat/cancel', data);
        return res;
    };

    suggestNewRate = async data => {
        const res = await this.getResource('/api/chat/offer', data);
        return res;
    };

    createOrder = async data => {
        const res = await this.getResource('/api/create-order', data);
        return res;
    };

    editOrder = async data => {
        const res = await this.getResource('/api/edit-order', data);
        return res;
    };

    cancelOrder = async data => {
        const res = await this.getResource('/api/cancel-order', data);
        return res;
    };

    activateOrder = async data => {
        const res = await this.getResource('/api/active-order', data);
        return res;
    };

    createWithdraw = async data => {
        const res = await this.getResource('/api/withdraw/create', data);
        return res;
    };

    regenerateWallet = async data => {
        const res = await this.getResource('/api/user/coin/regenerate', data);
        return res;
    };

    deleteOrder = async data => {
        const res = await this.getResource('/api/order/delete', data);
        return res;
    };

    userProfileEdit = async data => {
        const res = await this.getResource('/api/user/profile/edit', data);
        return res;
    };

    userVerification = async data => {
        const res = await this.getResource('/api/user/confirmation', data);
        return res;
    };

    emailConfirmation = async data => {
        const res = await this.getResource('/public/email-confirmation', data);
        return res;
    };

    confirmationDevice = async data => {
        const res = await this.getResource(`/public/confirmation-device/${data}`);
        return res;
    };

    userReview = async data => {
        const res = await this.getResource('/api/user-review/new', data);
        return res;
    };

    send2faCode = async data => {
        const res = await this.getResource('/api/2fa/code/check', data);
        return res;
    };

    passwordRecovery = async data => {
        const res = await this.getResource('/public/password/reset/email/send', data);
        return res;
    };

    friendConfirm = async data => {
        const res = await this.getResource('/api/user/friend-confirm', data);
        return res;
    };

    callArbiter = async data => {
        const res = await this.getResource('/api/chat/arbitrage', data);
        return res;
    };

    arbiterСonfirmedPayment = async data => {
        const res = await this.getResource('/api/chat/arbiter/payment/accept', data);
        return res;
    };

    arbiterDeclinedPayment = async data => {
        const res = await this.getResource('/api/chat/arbiter/payment/decline', data);
        return res;
    };

    revokeAccess = async data => {
        const res = await this.getResource('/api/user/revoke-access', data);
        return res;
    };

    blockUser = async data => {
        const res = await this.getResource(`/api/user/black-list/block/${data}`);
        return res;
    };

    restoreAccount = async (token, data) => {
        const res = await this.getResource(`/public/user/restore/${token}`, data);
        return res;
    };

    createNewCard = async data => {
        const res = await this.getResource('/api/user-bank-card/new', data);
        return res;
    };

    questionForm = async data => {
        const res = await this.getResource('/api/feedback/create', data);
        return res;
    };

    questionFormAnonymous = async data => {
        const res = await this.getResource('/public/feedback/create', data);
        return res;
    };

    setCookie = async data => {
        const res = await this.getResource('/api/socket/cookie/set', data);
        return res;
    };

    setLanguage = async data => {
        const res = await this.getResource(`/api/user/set-language/${data}`);
        return res;
    };
}
