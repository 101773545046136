import React from 'react';
import { withRouter } from 'react-router-dom';
import FooterSite from './footer-site';
import FooterPersonalArea from './footer-personal-area';

import { personalAreaPath } from '../../../constants';
import { compose } from '../../../utils';

const Footer = () => {
    const pathName = window.location.pathname;
    const matchPathName = pathName.split('/')[1];

    const activeFooter = `/${matchPathName}` === personalAreaPath ? (
        <FooterPersonalArea />
    ) : (
        <FooterSite />
    );

    return activeFooter;
};

export default compose(
    withRouter,
)(Footer);
