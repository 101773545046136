import xlm from '../components/assets/images/icons/xml.svg';
import BINANCE from '../components/assets/images/icons/binance-coin.svg';
import BTC from '../components/assets/images/icons/BTC.svg';
import bitcoinCash from '../components/assets/images/icons/bitcoinCash.svg';
import DASH from '../components/assets/images/icons/DASH.svg';
import Ecro from '../components/assets/images/icons/Ecro.svg';
import EDC from '../components/assets/images/icons/EDC.svg';
import ETH from '../components/assets/images/icons/ETH.svg';
import ERC from '../components/assets/images/icons/ERC.svg';
import XRP from '../components/assets/images/icons/XRP.svg';
import TRX from '../components/assets/images/icons/tron.svg';
import LINK from '../components/assets/images/icons/chainlink.svg';
import XTZ from '../components/assets/images/icons/tezos.svg';
import Litecoin from '../components/assets/images/icons/Litecoin.svg';
import tether from '../components/assets/images/icons/tether.svg';
import leo from '../components/assets/images/icons/LEO.svg';
import neo from '../components/assets/images/icons/NEO.svg';
import gas from '../components/assets/images/icons/GAS.svg';
import atom from '../components/assets/images/icons/ATOM.svg';
import ont from '../components/assets/images/icons/ONT.svg';
import ong from '../components/assets/images/icons/ONG.svg';
import bsv from '../components/assets/images/icons/BSV.svg';
import doge from '../components/assets/images/icons/Doge.svg';
import usdc from '../components/assets/images/icons/USDC.svg';
import algo from '../components/assets/images/icons/ALGO.svg';
import btt from '../components/assets/images/icons/BTT.svg';
import qtum from '../components/assets/images/icons/QTUM.svg';
import waves from '../components/assets/images/icons/WAVES.svg';
import zil from '../components/assets/images/icons/ZIL.svg';

const coinsData = [
    {
        ticker: 'BTC',
        name: 'Bitcoin (BTC)',
        image: BTC,
        soon: false,
        link: 'https://bitcoin.org/',
    },
    {
        ticker: 'tether',
        name: 'Tether (USDT)',
        image: tether,
        soon: false,
        link: 'https://tether.to/',
    },
    {
        ticker: 'EDC',
        name: 'EDC Blockchain (EDC)',
        image: EDC,
        soon: false,
        link: 'https://blockchain.mn/',
    },
    {
        ticker: 'ETH',
        name: 'Ethereum (ETH)',
        image: ETH,
        soon: false,
        link: 'https://ethereum.org/',
    },
    {
        ticker: 'Litecoin',
        name: 'Litecoin (LTC)',
        image: Litecoin,
        soon: false,
        link: 'https://litecoin.org/',
    },
    {
        ticker: 'DASH',
        name: 'Dashcoin (DASH)',
        image: DASH,
        soon: false,
        link: 'https://www.dash.org/',
    },
    {
        ticker: 'bitcoinCash',
        name: 'Bitcoin Cash (BCH)',
        image: bitcoinCash,
        soon: false,
        link: 'https://www.bitcoincash.org/',
    },
    {
        ticker: 'ERC',
        name: 'ERC tokens',
        image: ERC,
        soon: false,
        link: 'https://ethereum.org/',
    },
    {
        ticker: 'Ecro',
        name: 'ECRO (ECRO)',
        image: Ecro,
        soon: false,
        link: 'https://ecrofund.com/',
    },
    {
        ticker: 'BINANCE',
        name: 'Binance coin (BNB)',
        image: BINANCE,
        soon: false,
        link: 'https://www.binance.com/',
    },
    {
        ticker: 'xlm',
        name: 'Stellar (XLM)',
        image: xlm,
        soon: false,
        link: 'https://www.stellar.org/',
    },
    {
        ticker: 'TRX',
        name: 'Tron (TRX)',
        image: TRX,
        soon: false,
        link: 'https://tron.network/',
    },
    {
        ticker: 'XRP',
        name: 'XRP (XRP)',
        image: XRP,
        soon: false,
        link: 'https://ripple.com/xrp/',
    },
    {
        ticker: 'XTZ',
        name: 'Tezos (XTZ)',
        image: XTZ,
        soon: false,
        link: 'https://tezos.com/',
    },
    {
        ticker: 'LINK',
        name: 'Chainlink (LINK)',
        image: LINK,
        soon: false,
        link: 'https://chain.link/',
    },
    {
        ticker: 'NEO',
        name: 'Neo (NEO)',
        image: neo,
        soon: false,
        link: 'https://neo.org/',
    },
    {
        ticker: 'GAS',
        name: 'Gas (GAS)',
        image: gas,
        soon: false,
        link: 'https://neo.org/',
    },
    {
        ticker: 'ATOM',
        name: 'Cosmos (ATOM)',
        image: atom,
        soon: false,
        link: 'https://cosmos.network/',
    },
    {
        ticker: 'ONT',
        name: 'Ontology (ONT)',
        image: ont,
        soon: false,
        link: 'https://ont.io/',
    },
    {
        ticker: 'ONG',
        name: ' Ontology Gas (ONG)',
        image: ong,
        soon: false,
        link: 'https://ont.io/',
    },
    {
        ticker: 'BSV',
        name: 'Bitcoin SV (BSV)',
        image: bsv,
        soon: false,
        link: 'https://bitcoinsv.io/',
    },
    {
        ticker: 'LEO',
        name: 'Unus Sed Leo (LEO)',
        image: leo,
        soon: false,
        link: 'https://bitfinex.com/',
    },
    {
        ticker: 'DOGE',
        name: 'Dogecoin (DOGE)',
        image: doge,
        soon: false,
        link: 'https://bitfinex.com/',
    },
    {
        ticker: 'USDC',
        name: 'USD Coin (USDC)',
        image: usdc,
        soon: false,
        link: 'https://www.circle.com/',
    },

    {
        ticker: 'QTUM',
        name: 'Qtum (QTUM)',
        image: qtum,
        soon: false,
        link: 'https://qtum.org/',
    },
    {
        ticker: 'WAVES',
        name: 'Waves(WAVES)',
        image: waves,
        soon: false,
        link: 'https://wavesprotocol.org/',
    },
    {
        ticker: 'ALGO',
        name: 'Algorand (ALGO)',
        image: algo,
        soon: false,
        link: 'https://www.algorand.com/',
    },
    {
        ticker: 'BTT',
        name: 'BitTorrent (BTT)',
        image: btt,
        soon: false,
        link: 'https://www.bittorrent.com/',
    },
    {
        ticker: 'ZIL',
        name: 'Ziliqua (ZIL)',
        image: zil,
        soon: false,
        link: 'https://www.zilliqa.com/',
    },
];

export default coinsData;
