import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Header from '../layouts/header';
import Footer from '../layouts/footer';
import {
    HomePage,
    PageNotFound,
    ContactPage,
} from '../pages';

import {
    contactPath,
} from '../../constants';
import ScrollToTop from '../../utils/scrollToTop';

import '../assets/styles/reset.scss';
import './app.scss';
import '../assets/styles/fonts.scss';

const App = () => (
    <Router>
        <ScrollToTop>
            <Header />
            <Switch>
                <Route path="/" component={HomePage} exact />
                <Route path={contactPath} component={ContactPage} />
                <Route component={PageNotFound} />
            </Switch>
            <Footer />
        </ScrollToTop>
    </Router>
);
export default App;
