/* eslint-disable */
const REQUIRED_2FA_CODE = 'REQUIRED_2FA_CODE';
const INVALID_2FA_CODE = 'INVALID_2FA_CODE';
const USER_NOT_CONFIRMED = 'USER_NOT_CONFIRMED';
const MAXIMUM_FRIEND_CONFIRMATIONS_REACHED = 'MAXIMUM_FRIEND_CONFIRMATIONS_REACHED';
const USER_ALREADY_CONFIRMED = "USER_ALREADY_CONFIRMED";
const DEVICE_NOT_TRUSTED = 'DEVICE_NOT_TRUSTED';
const TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS';
const YOU_ALREADY_CONFIRMED_THIS_USER = 'YOU_ALREADY_CONFIRMED_THIS_USER';
const ORDER_MAX_AMOUNT_ERROR = 'ORDER_MAX_AMOUNT_ERROR';
const UNDEFINED_USER_WITH_USERNAME = 'UNDEFINED_USER_WITH_USERNAME';
const INVALID_TOKEN = 'INVALID_TOKEN';
const UNDEFINED_USER_WITH_TOKEN = 'UNDEFINED_USER_WITH_TOKEN';
const USER_BLOCKED = 'USER_BLOCKED';
const YOU_CANT_CONFIRM_YOURSELF = 'YOU_CANT_CONFIRM_YOURSELF';
const YOU_ARE_NOT_CONFIRMED = 'YOU_ARE_NOT_CONFIRMED';
const TOO_MANY_REQUESTS_TO_RESET_PASSWORD = 'TOO_MANY_REQUESTS_TO_RESET_PASSWORD';
const ADDRESS_IS_NOT_VALID = 'ADDRESS_IS_NOT_VALID';
const USER_HAS_PENDING_ORDERS = 'USER_HAS_PENDING_ORDERS';
const CHAT_MESSAGE_MAX_ERROR = 'CHAT_MESSAGE_MAX_ERROR';
const CHAT_MESSAGE_MIN_ERROR = 'CHAT_MESSAGE_MIN_ERROR';
const USER_WITHDRAW_MAX_ERROR = 'USER_WITHDRAW_MAX_ERROR';
const USER_WITHDRAW_MIN_ERROR = 'USER_WITHDRAW_MIN_ERROR';
const NOT_ENOUGH_BALANCE = 'NOT_ENOUGH_BALANCE';
const INVALID_CONFIRMATION_TOKEN = 'INVALID_CONFIRMATION_TOKEN';
const MAXIMUM_CARDS_LIMIT_REACHED = 'MAXIMUM_CARDS_LIMIT_REACHED';
const ORDER_HAS_BEEN_COMPLETED_BY_ANOTHER_USER = 'ORDER_HAS_BEEN_COMPLETED_BY_ANOTHER_USER';
const NOT_ENOUGH_BALANCE_FOR_USER_COIN = 'NOT_ENOUGH_BALANCE_FOR_USER_COIN';
const USER_NOT_FOUND = 'USER_NOT_FOUND';
const UNABLE_TO_SET_ORDER_RATE = 'UNABLE_TO_SET_ORDER_RATE';
const ORDER_HAS_OPENED_CHATS = 'ORDER_HAS_OPENED_CHATS';
const UNABLE_TO_DELETE_USER_HAS_ARBITRAGE_ORDERS = 'UNABLE_TO_DELETE_USER_HAS_ARBITRAGE_ORDERS';
const UNABLE_TO_DELETE_USER_HAS_PENDING_ORDERS = 'UNABLE_TO_DELETE_USER_HAS_PENDING_ORDERS';
const UNABLE_TO_BLOCK_USER_HAS_ARBITRAGE_ORDERS = 'UNABLE_TO_BLOCK_USER_HAS_ARBITRAGE_ORDERS';
const UNABLE_TO_BLOCK_USER_HAS_PENDING_ORDERS = 'UNABLE_TO_BLOCK_USER_HAS_PENDING_ORDERS';
const UNABLE_TO_GENERATE_ADDRESS = 'UNABLE_TO_GENERATE_ADDRESS';
const YOU_ARE_NOT_ALLOWED_TO_REGENERATE_ADDRESS = 'YOU_ARE_NOT_ALLOWED_TO_REGENERATE_ADDRESS';
const CANNOT_FIND_USER_OR_USER_EMAIL_ALREADY_CONFIRMED = 'CANNOT_FIND_USER_OR_USER_EMAIL_ALREADY_CONFIRMED';

export {
    REQUIRED_2FA_CODE,
    INVALID_2FA_CODE,
    MAXIMUM_FRIEND_CONFIRMATIONS_REACHED,
    USER_ALREADY_CONFIRMED,
    DEVICE_NOT_TRUSTED,
    USER_NOT_CONFIRMED,
    TOO_MANY_REQUESTS,
    YOU_ALREADY_CONFIRMED_THIS_USER,
    ORDER_MAX_AMOUNT_ERROR,
    UNDEFINED_USER_WITH_USERNAME,
    INVALID_TOKEN,
    UNDEFINED_USER_WITH_TOKEN,
    USER_BLOCKED,
    YOU_CANT_CONFIRM_YOURSELF,
    YOU_ARE_NOT_CONFIRMED,
    TOO_MANY_REQUESTS_TO_RESET_PASSWORD,
    ADDRESS_IS_NOT_VALID,
    USER_HAS_PENDING_ORDERS,
    CHAT_MESSAGE_MAX_ERROR,
    CHAT_MESSAGE_MIN_ERROR,
    USER_WITHDRAW_MAX_ERROR,
    USER_WITHDRAW_MIN_ERROR,
    NOT_ENOUGH_BALANCE,
    INVALID_CONFIRMATION_TOKEN,
    MAXIMUM_CARDS_LIMIT_REACHED,
    ORDER_HAS_BEEN_COMPLETED_BY_ANOTHER_USER,
    NOT_ENOUGH_BALANCE_FOR_USER_COIN,
    USER_NOT_FOUND,
    UNABLE_TO_SET_ORDER_RATE,
    ORDER_HAS_OPENED_CHATS,
    UNABLE_TO_DELETE_USER_HAS_ARBITRAGE_ORDERS,
    UNABLE_TO_DELETE_USER_HAS_PENDING_ORDERS,
    UNABLE_TO_BLOCK_USER_HAS_ARBITRAGE_ORDERS,
    UNABLE_TO_BLOCK_USER_HAS_PENDING_ORDERS,
    UNABLE_TO_GENERATE_ADDRESS,
    YOU_ARE_NOT_ALLOWED_TO_REGENERATE_ADDRESS,
    CANNOT_FIND_USER_OR_USER_EMAIL_ALREADY_CONFIRMED,
};
