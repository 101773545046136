import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import TextTruncate from 'react-text-truncate';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ReactWOW from 'react-wow';

import coinsData from '../../../helpers/coins-data';
import browserData from '../../../helpers/browser-data';
// import currentExtensionLink from '../../../helpers/extension-link';
import Img from '../../small-components/img';
import mouse from '../../assets/images/mouse.svg';
import banner from './images/banner.png';
import aboutBg from './images/aboutBg.svg';
import secure from './images/secure.svg';
// import partner1 from './images/partner1.svg';
import partner2 from './images/partner2.svg';
// import partner3 from './images/partner3.svg';
import nonCustodial from './images/non-custodial.svg';
import easyAndFast from './images/easyAndFast.svg';
import style from './home-page.module.scss';
import step1 from './images/step1.svg';
import step2 from './images/step2.svg';
import step3 from './images/step3.svg';
import step4 from './images/step4.svg';
import step5 from './images/step5.svg';
import stepLine from './images/stepLine.svg';

const HomePage = () => {
    const { t } = useTranslation();

    const bgImage = {
        backgroundImage: `url(${banner})`,
        backgroundRepeat: 'no-repeat',
        height: '100%',
        backgroundSize: 'cover',
    };

    const aboutBgImage = {
        backgroundImage: `url(${aboutBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    };

    const advantagesData = [
        {
            advantages: secure,
            alt: 'secure',
            title: t('extension.secure'),
            subTitle: t('extension.secureSubtitle'),
        },
        {
            advantages: nonCustodial,
            alt: 'nonCustodial',
            title: t('extension.nonCustodial'),
            subTitle: t('extension.nonCustodialSubtitle'),
        },
        {
            advantages: easyAndFast,
            alt: 'easyAndFast',
            title: t('extension.easyAndFast'),
            subTitle: t('extension.easyAndFastSubtitle'),
        },
    ];
    const imgAboveLine = [
        {
            id: 1,
            howToStart: step1,
            alt: 'step1',
            title: t('howToStart.stepTxt1'),
            step: t('howToStart.step'),
        },
        {
            id: 3,
            howToStart: step3,
            alt: 'step3',
            title: t('howToStart.stepTxt3'),
            step: t('howToStart.step'),
        },
        {
            id: 5,
            howToStart: step5,
            alt: 'step5',
            title: t('howToStart.stepTxt5'),
            step: t('howToStart.step'),
        },
    ];

    const imgUnderLine = [
        {
            id: 2,
            howToStart: step2,
            alt: 'step2',
            title: t('howToStart.stepTxt2'),
            step: t('howToStart.step'),
        },
        {
            id: 4,
            howToStart: step4,
            alt: 'step4',
            title: t('howToStart.stepTxt4'),
            step: t('howToStart.step'),
        },
    ];

    const imgWithoutLine = [
        {
            id: 1,
            howToStart: step1,
            alt: 'step1',
            title: t('howToStart.stepTxt1'),
            step: t('howToStart.step'),
        },
        {
            id: 2,
            howToStart: step2,
            alt: 'step2',
            title: t('howToStart.stepTxt2'),
            step: t('howToStart.step'),
        },
        {
            id: 3,
            howToStart: step3,
            alt: 'step3',
            title: t('howToStart.stepTxt3'),
            step: t('howToStart.step'),
        },
        {
            id: 4,
            howToStart: step4,
            alt: 'step4',
            title: t('howToStart.stepTxt4'),
            step: t('howToStart.step'),
        },
        {
            id: 5,
            howToStart: step5,
            alt: 'step5',
            title: t('howToStart.stepTxt5'),
            step: t('howToStart.step'),
        },
    ];
    return (
        <Fragment>
            <div className={style.firstScreen} style={bgImage}>
                <div className={style.firstScreen__leftSide}>
                    <div>
                        <ReactWOW animation="fadeInUp" delay="0.30s">
                            <div className={style.firstScreen__leftSide_title}>
                                <TextTruncate
                                    line={2}
                                    element="h1"
                                    truncateText="…"
                                    text={t('extension.title')}
                                />
                            </div>
                        </ReactWOW>
                    </div>
                    <ReactWOW animation="fadeInUp" delay="0.45s">
                        <div>
                            <a
                                href={`${process.env.PUBLIC_URL}/Crocobit_extension_0.8.0.zip`}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                                className={style.firstScreen__leftSide_btn}
                            >
                                {t('extension.getExtension')}
                            </a>

                        </div>
                    </ReactWOW>
                    <ReactWOW animation="fadeInUp" delay="0.75s">
                        <AnchorLink href="#about">
                            <div className={style.firstScreen__scrollDown}>
                                <img
                                    className={style.firstScreen__scrollDown_mouse}
                                    src={mouse}
                                    alt="mouse"
                                />
                                {t('extension.scrollDown')}
                            </div>
                        </AnchorLink>
                    </ReactWOW>
                </div>
                <ReactWOW animation="fadeInUp" delay="0.9s">
                    <div className={style.firstScreen__rightSide} />
                </ReactWOW>
            </div>
            <ReactWOW animation="fadeInUp" delay="0.05s">
                <div className={style.howToStart}>
                    <p className={style.howToStart__title}>{t('howToStart.title')}</p>
                    <div className={style.howToStart__txt_container}>
                        <p className={style.howToStart__txt}>{t('howToStart.txt1')}</p>
                        <p className={style.howToStart__txt}>{t('howToStart.txt2')}</p>
                        <p className={style.howToStart__txt}>{t('howToStart.txt3')}</p>
                    </div>
                    <div className={style.howToStart__container}>
                        <div className={style.howToStart__wrapper}>
                            {imgAboveLine.map(item => {
                                const {
                                    id, step, howToStart, alt, title,
                                } = item;
                                return (
                                    <div
                                        key={alt}
                                        className={style.howToStart__wrapper_itemAbove}
                                    >
                                        <Img
                                            className={style.howToStart__wrapper_img}
                                            src={howToStart}
                                            alt={alt}
                                        />
                                        <div className={style.howToStart__wrapper_step}>
                                            <span>
                                                {step} {id}
                                            </span>
                                            {title}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <Img
                            className={style.howToStart__stepLine}
                            src={stepLine}
                            alt="stepLine"
                        />
                        <div className={style.howToStart__wrapper}>
                            {imgUnderLine.map(item => {
                                const {
                                    id, step, howToStart, alt, title,
                                } = item;
                                return (
                                    <div
                                        key={alt}
                                        className={style.howToStart__wrapper_itemUnder}
                                    >
                                        <Img
                                            className={style.howToStart__wrapper_img}
                                            src={howToStart}
                                            alt={alt}
                                        />
                                        <div className={style.howToStart__wrapper_step}>
                                            <span>
                                                {step} {id}
                                            </span>
                                            {title}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={style.howToStart__wrapper_mobile}>
                            {imgWithoutLine.map(item => {
                                const {
                                    id, step, howToStart, alt, title,
                                } = item;
                                return (
                                    <div
                                        key={alt}
                                        className={style.howToStart__wrapper_itemMobile}
                                    >
                                        <Img
                                            className={style.howToStart__wrapper_img}
                                            src={howToStart}
                                            alt={alt}
                                        />
                                        <div className={style.howToStart__wrapper_step}>
                                            <span>
                                                {step} {id}
                                            </span>
                                            {title}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <a
                            href={`${process.env.PUBLIC_URL}/Crocobit_extension_0.8.0.zip`}
                            download
                            className={style.howToStart__download}
                        >
                            Download EXTENSION
                        </a>
                    </div>
                </div>
            </ReactWOW>
            <ReactWOW animation="fadeInUp" delay="0.05s">
                <div className={style.about} id="about" style={aboutBgImage}>
                    <h3 className={style.about__title}>{t('extension.about')}</h3>
                    <p className={style.about__title_firstRow}>
                        {t('extension.aboutFirstRow')}
                    </p>
                    <p className={style.about__title_secondRow}>
                        {t('extension.aboutThirdRow')}
                        <a href="mailto:wallet.support@crocobit.net">
                            wallet.support@crocobit.net
                        </a>. {t('extension.aboutThirdRowSecondPart')}
                    </p>
                    <p className={style.about__title_thirdRow}>
                        {t('extension.privacyPolicy')}
                        <a
                            href="https://p2p.crocobit.net/privacy-policy#walletExtension"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={style.footer__links}
                        >
                            {t('extension.here')}.
                        </a>
                    </p>
                </div>
            </ReactWOW>
            <ReactWOW animation="fadeInUp" delay="0.05s">
                <div className={style.advantages}>
                    <p className={style.advantages__title}>
                        {t('extension.advantagesTitle')}
                    </p>
                    <div className={style.advantages__wrapper}>
                        {advantagesData.map(item => {
                            const {
                                advantages, alt, title, subTitle,
                            } = item;
                            return (
                                <div key={alt} className={style.advantages__wrapper_item}>
                                    <img
                                        className={style.advantages__wrapper_img}
                                        src={advantages}
                                        alt={alt}
                                    />
                                    <div className={style.advantages__titleWrapper}>
                                        <p
                                            className={
                                                style.advantages__titleWrapper_title
                                            }
                                        >
                                            {title}
                                        </p>
                                        <p
                                            className={
                                                style.advantages__titleWrapper_subTitle
                                            }
                                        >
                                            {subTitle}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </ReactWOW>
            <ReactWOW animation="fadeInUp" delay="0.05s">
                <div className={style.partners}>
                    <p className={style.partners__title}>{t('extension.partners')}</p>
                    <div className={style.partners__wrapper}>
                        {/* <a
                            href="https://ecrosystem.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={style.partners__item}
                        >
                            <img src={partner1} alt="EcroSystem" />
                        </a> */}
                        <a
                            href="https://blockchair.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={style.partners__item}
                        >
                            <img src={partner2} alt="BlockChair" />
                        </a>
                        {/* <a
                            href="https://embily.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={style.partners__item}
                        >
                            <img src={partner3} alt="Embily" />
                        </a> */}
                    </div>
                </div>
            </ReactWOW>
            <ReactWOW animation="fadeInUp" delay="0.05s">
                <div className={style.supportedAssets}>
                    <p className={style.supportedAssets__title}>
                        {t('extension.supportedAssets')}
                    </p>
                    <div className={style.supportedAssets__wrapper}>
                        {coinsData.map(item => {
                            const {
                                ticker, name, image, soon, link,
                            } = item;

                            return (
                                <a
                                    href={link}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    key={ticker}
                                    className={style.supportedAssets__wrapper_item}
                                >
                                    {soon ? (
                                        <p
                                            className={
                                                style.supportedBrowsers__wrapper_soonAssets
                                            }
                                        >
                                            Soon
                                        </p>
                                    ) : null}
                                    <img
                                        className={style.supportedAssets__wrapper_img}
                                        src={image}
                                        alt={ticker}
                                    />
                                    <p
                                        className={
                                            style.supportedAssets__wrapper_subTitle
                                        }
                                    >
                                        {name}
                                    </p>
                                </a>
                            );
                        })}
                    </div>
                </div>
            </ReactWOW>
            <ReactWOW animation="fadeInUp" delay="0.05s">
                <div className={style.supportedBrowsers}>
                    <p className={style.supportedBrowsers__title}>
                        {t('extension.supportedBrowsers')}
                    </p>
                    <div className={style.supportedBrowsers__wrapper}>
                        {browserData.map(item => {
                            const { link, image, name } = item;

                            return (
                                <a
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={style.supportedBrowsers__wrapper_item}
                                    key={name}
                                >
                                    <img
                                        className={style.supportedBrowsers__wrapper_img}
                                        src={image}
                                        alt="name"
                                    />
                                    <p
                                        className={
                                            style.supportedBrowsers__wrapper_subTitle
                                        }
                                    >
                                        {name}
                                    </p>
                                </a>
                            );
                        })}
                    </div>
                </div>
            </ReactWOW>
        </Fragment>
    );
};

export default HomePage;
