const loginPath = '/login';
const registartionPath = '/registration';
const ordersPath = '/orders';
const aboutPath = '/about';
const contactPath = '/contact';
const termOfServicePath = '/term-of-service';
const privacyPolicyPath = '/privacy-policy';
const personalAreaPath = '/personal-area';
const faqPath = '/faq';
const feesPath = '/fees';
const p2p = '/p2p';
const chat = '/chat';
const room = '/room';
const dashboardPath = '/dashboard';
const wallet = '/wallet';
const history = '/history';
const profilePath = '/profile';
const settingsPath = '/settings';
const newOrder = '/new-order';
const advertisement = '/advertisement';
const editPath = '/edit';
const feePath = '/fee';
const confirmationPath = '/confirmation';
const confirmationDevicesPath = '/confirmation-devices';
const recoveryPasswordPath = '/password/reset';
const restoreAccountPath = '/user/restore';
const extensionPath = '/extension';

export {
    loginPath,
    registartionPath,
    ordersPath,
    aboutPath,
    contactPath,
    termOfServicePath,
    privacyPolicyPath,
    personalAreaPath,
    faqPath,
    feesPath,
    p2p,
    chat,
    room,
    dashboardPath,
    wallet,
    history,
    profilePath,
    settingsPath,
    feePath,
    newOrder,
    advertisement,
    editPath,
    confirmationPath,
    confirmationDevicesPath,
    recoveryPasswordPath,
    restoreAccountPath,
    extensionPath,
};
