import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { compose } from '../../../utils';
import logo from '../../assets/images/logo.svg';
import facebook from '../../assets/images/social-icons/facebook.svg';
// import talk from '../../assets/images/social-icons/talk.svg';
// import btc from '../../assets/images/social-icons/btc.svg';
import instagram from '../../assets/images/social-icons/instagram.svg';
import telegram from '../../assets/images/social-icons/telegram.svg';
// import weChat from '../../assets/images/social-icons/weChat.svg';
import twitter from '../../assets/images/social-icons/twitter.svg';
import youtube from '../../assets/images/social-icons/youtube.svg';
import style from './footer.module.scss';

const FooterLanding = () => {
    const { t } = useTranslation();
    let currentExtensionLink;
    const { userAgent } = navigator;

    if (userAgent.indexOf('Firefox') > -1) {
        currentExtensionLink = 'https://addons.mozilla.org/en-US/firefox/addon/crocobit-wallet/';
    } else if (userAgent.indexOf('Opera') > -1) {
        currentExtensionLink = 'https://www.youtube.com/watch?v=pDqoSIU3vkI';
    } else if (userAgent.indexOf('Trident') > -1) {
        currentExtensionLink = 'https://chrome.google.com/webstore/detail/crocobit-wallet/pnlfjmlcjdjgkddecgincndfgegkecke';
    } else if (userAgent.indexOf('Edge') > -1) {
        currentExtensionLink = 'https://chrome.google.com/webstore/detail/crocobit-wallet/pnlfjmlcjdjgkddecgincndfgegkecke';
    } else if (userAgent.indexOf('Chrome') > -1) {
        currentExtensionLink = 'https://chrome.google.com/webstore/detail/crocobit-wallet/pnlfjmlcjdjgkddecgincndfgegkecke';
    } else if (userAgent.indexOf('Safari') > -1) {
        currentExtensionLink = 'https://chrome.google.com/webstore/detail/crocobit-wallet/pnlfjmlcjdjgkddecgincndfgegkecke';
    } else {
        currentExtensionLink = 'https://chrome.google.com/webstore/detail/crocobit-wallet/pnlfjmlcjdjgkddecgincndfgegkecke';
    }

    return (
        <footer className={style.footer}>
            <div className={style.footer__linksWrapper}>
                <Link to="/">
                    <img className={style.footer__logo} src={logo} alt="logo" />
                </Link>
                <div className={style.footer__info}>
                    <h3 className={style.footer__title}>{t('footer.products')}</h3>
                    <a
                        href="https://p2p.crocobit.net/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className={style.footer__links}
                    >
                        Crocobit P2P
                    </a>
                    <a
                        href={currentExtensionLink}
                        rel="noopener noreferrer"
                        target="_blank"
                        className={style.footer__links}
                    >
                        Crocobit extension
                    </a>
                    {/* <a
                        href="https://ecrosystem.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className={style.footer__links}
                    >
                        ECRO System
                    </a> */}
                </div>
                <div className={style.footer__support}>
                    <h3 className={style.footer__title}>{t('footer.contacts')}</h3>
                    <a
                        href="mailto:wallet.support@crocobit.net"
                        className={style.footer__links}
                    >
                        wallet.support@crocobit.net
                    </a>
                </div>
                <div className={style.footer__services}>
                    <h3 className={style.footer__title}>{t('footer.community')}</h3>
                    <div className={style.footer__socialIconsWrapper}>
                        <a
                            className={style.footer__bottomWrapper_socialIcons}
                            href="https://t.me/crocobit_official"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <img src={telegram} alt="telegram" />
                        </a>
                        {/* <a
                            className={style.footer__bottomWrapper_socialIcons}
                            href="/"
                            target="_blank"
                        >
                            <img src={talk} alt="talk" />
                        </a> */}
                        <a
                            className={style.footer__bottomWrapper_socialIcons}
                            href="https://twitter.com/CrocobitNet"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <img src={twitter} alt="twitter" />
                        </a>
                        <a
                            className={style.footer__bottomWrapper_socialIcons}
                            href="https://www.facebook.com/crocobit/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={facebook} alt="facebook" />
                        </a>
                        <a
                            className={style.footer__bottomWrapper_socialIcons}
                            href="https://www.instagram.com/crocobit_official/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <img src={instagram} alt="instagram" />
                        </a>
                        {/* <a
                            className={style.footer__bottomWrapper_socialIcons}
                            href="/"
                            target="_blank"
                        >
                            <img src={weChat} alt="weChat" />
                        </a> */}
                        {/* <a
                            className={style.footer__bottomWrapper_socialIcons}
                            href="/"
                            target="_blank"
                        >
                            <img src={btc} alt="btc" />
                        </a> */}
                        <a
                            className={style.footer__bottomWrapper_socialIcons}
                            href="https://www.youtube.com/channel/UCIHG33F6JiWLnGRBQN-onPA/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={youtube} alt="youtube" />
                        </a>
                    </div>
                </div>
            </div>
            <div className={style.footer__bottomWrapper}>
                <p className={style.footer__copyright_content}>
                    Copyright
                    <span className={style.footer__copyright_symbol}>&#169;</span>
                    <span className={style.footer__copyright_p2p}>Crocobit</span>
                </p>
            </div>
        </footer>
    );
};

export default compose()(FooterLanding);
