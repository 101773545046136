import React from 'react';
import { withRouter } from 'react-router-dom';
import HeaderSite from './header-site';

import { compose } from '../../../utils';

const Header = () => <HeaderSite />;

export default compose(
    withRouter,
)(Header);
