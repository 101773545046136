import brave from '../components/assets/images/icons/brave.svg';
import chrome from '../components/assets/images/icons/chrome.svg';
import firefox from '../components/assets/images/icons/firefox.svg';
import edge from '../components/assets/images/icons/microsoft-edge.svg';
import opera from '../components/assets/images/icons/opera.svg';
import Yandex from '../components/assets/images/icons/Yandex.svg';

const browserData = [
    {
        link:
            'https://chrome.google.com/webstore/detail/crocobit-wallet/pnlfjmlcjdjgkddecgincndfgegkecke',
        image: chrome,
        name: 'Chrome',
    },
    {
        link: 'https://www.youtube.com/watch?v=pDqoSIU3vkI',
        image: opera,
        name: 'Opera',
    },
    {
        link: 'https://addons.mozilla.org/en-US/firefox/addon/crocobit-wallet/',
        image: firefox,
        name: 'Firefox',
    },
    {
        link:
            'https://chrome.google.com/webstore/detail/crocobit-wallet/pnlfjmlcjdjgkddecgincndfgegkecke',
        image: Yandex,
        name: 'Yandex',
    },
    {
        link:
            'https://chrome.google.com/webstore/detail/crocobit-wallet/pnlfjmlcjdjgkddecgincndfgegkecke',
        image: brave,
        name: 'Brave',
    },
    {
        link:
            'https://chrome.google.com/webstore/detail/crocobit-wallet/pnlfjmlcjdjgkddecgincndfgegkecke',
        image: edge,
        name: 'Edge',
    },
];

export default browserData;
